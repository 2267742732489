import React from 'react';

import Logo from '../../static/images/logo.svg';

const LandingScreen = () => {
  return (
    <div
      style={{
        height: '100dvh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={Logo} style={{ width: 300, height: 'auto', objectFit: 'contain' }} />
    </div>
  );
};

LandingScreen.id = 'com.PulseRoute.Landing';

export default LandingScreen;
