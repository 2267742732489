import React from 'react';
import { Route, Switch } from 'react-router';

import Landing from '../Landing';

const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
    </Switch>
  );
};

export default App;
